import { Stack, Typography } from "@mui/material";
import { Pod1umLogo } from "../../../logo/Pod1umLogo";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { MaterialFooterSocials } from "./MaterialFooterSocials";
import { MaterialFooterGetTheApp } from "./MaterialFooterGetTheApp";
import { MaterialFooterHelpLinks } from "./MaterialFooterHelpLinks";
import Link from "next/link";

export const MaterialFooter = () => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <div className={"flex mt-[100px]"}>
      <div className={"flex flex-col space-y-[100px] w-full"}>
        <div className="flex flex-col space-y-8 lg:flex-row ">
          <div className="space-y-[24px] lg:w-1/3">
            <div className="w-[80px] h-[95px]">
              <Pod1umLogo />
            </div>

            <Typography
              sx={{
                color: theme.componentColors.surface.onSurface,
              }}
            >
              Experience the POD1UM difference, try a free workout today.{" "}
              <br className="lg:hidden" />
              Your journey to peak performance starts here.
            </Typography>
            <MaterialFooterSocials />
          </div>

          <div className="flex flex-row lg:w-2/3 space-x-2 justify-around">
            <MaterialFooterHelpLinks
              title="Coaches"
              links={[
                {
                  title: "Apply",
                  link: "mailto:support@pod1um.com?subject=Coach Application",
                },
              ]}
            />

            <MaterialFooterHelpLinks
              title="Athletes"
              links={[{ title: "Pricing", link: "/payment" }]}
            />

            <MaterialFooterHelpLinks
              title="Support"
              links={[
                {
                  title: "Get Support",
                  link: "mailto:support@pod1um.com?subject=Athlete Support",
                },
              ]}
            />

            <MaterialFooterGetTheApp className="hidden lg:flex" />
          </div>
          <MaterialFooterGetTheApp className="flex lg:hidden" />
        </div>

        <Stack
          sx={{
            borderTop: "0.5px solid",
            borderTopColor: theme.componentColors.surface.onSurface,
          }}
          className="flex flex-col lg:flex-row justify-between items-center py-[20px]"
        >
          <Typography className="text-[18px] font-light ">
            Copyright &#169; 2024 Pod1um
          </Typography>

          <div className="flex items-center space-x-2 px-4">
            <Typography className="text-[18px] font-light">
              All rights Reserved
            </Typography>
            <Typography> | </Typography>
            <Link href="https://join.pod1um.com/terms/">
              <Typography
                className="text-[18px] font-light "
                sx={{
                  color: theme.componentColors.primary.primary,
                }}
              >
                Terms and Conditions
              </Typography>
            </Link>
            <Typography> | </Typography>
            <Link href="https://join.pod1um.com/terms/">
              <Typography
                sx={{
                  color: theme.componentColors.primary.primary,
                }}
                className="text-[18px] font-light"
              >
                {" "}
                Privacy Policy
              </Typography>
            </Link>
          </div>
        </Stack>
      </div>
    </div>
  );
};

import { Paper, Stack } from "@mui/material";
import { NavigationRail } from "../../menu/material/NavigationRail/NavigationRail";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { IMaterialLayout } from "../../../../interfaces/globalComponents/layout/material/IMaterialLayout";
import { NavigationDrawer } from "../../menu/material/navigationDrawer/NavigationDrawer";
import { setIsOpenShareModal as setOpenShareModalListing } from "../../../../redux/pages/trainingPlanSlice";
import { setIsOpenShareModal as setOpenShareModalCoach } from "../../../../redux/pages/specialistSlice";
import { setIsOpenShareModal as setOpenShareModalTeam } from "../../../../redux/pages/teamSlice";
import { MaterialFooter } from "./materialFooter/MaterialFooter";
import { RegisterModal } from "../../modals/material/registerModal/RegisterModal";
import { LoginModal } from "../../modals/material/loginModal/LoginModal";
import { useLoggedUser } from "../../../../hooks/user/useLoggedUser";
import { Snackbar } from "../../snackbar/Snackbar";
import { Navbar, NavbarMobile } from "../../menu/material/Navbar";
import { SearchBarMobile } from "../../menu/material/SearchBarMobile";
import { BottomAppBar } from "../../menu/material/BottomAppBar";
import { MaterialThemeProvider } from "./MaterialThemeProvider";
import { useRouter } from "next/router";

export const MaterialLayout = ({ children }: IMaterialLayout) => {
  useLoggedUser();

  const theme = useSelector((state: RootState) => state.theme);
  const menu = useSelector((state: RootState) => state.menu.value);
  const dispatch = useDispatch();
  const userRedux = useSelector((state: RootState) => state.user.value);
  const router = useRouter();
  const isOnLandingPages =
    !userRedux.loggedUser &&
    (router.asPath === "/" || router.asPath === "/coach");

  const handleShareModals = () => {
    dispatch(setOpenShareModalListing(false));
    dispatch(setOpenShareModalCoach(false));
    dispatch(setOpenShareModalTeam(false));
  };

  return (
    <MaterialThemeProvider>
      <Snackbar />

      <div onClick={() => handleShareModals()}>
        <RegisterModal />
        <LoginModal />

        <Paper
          className="min-h-[100vh] scrollbar-hide"
          sx={{
            backgroundColor: theme.componentColors.surface.surfContainer,
            backgroundImage: "none",
          }}
        >
          {userRedux.loggedUser && <NavigationRail />}

          <main className={`${userRedux.loggedUser ? "lg:ml-[100px]" : ""}`}>
            {isOnLandingPages ? <NavbarMobile /> : <SearchBarMobile />}

            <Navbar />

            <Stack>
              {userRedux.loggedUser && <NavigationDrawer />}
              <Stack
                className={`pb-[100px] lg:pb-0 ${
                  menu.showDrawer
                    ? "lg:w-[79%] lg:pl-[18%] 2xl:w-[80%] 2xl:pl-[15%]"
                    : ""
                } max-w-[1600px] w-[94%] mx-auto ${
                  userRedux.loggedUser ? "lg:w-[calc(100%-4.75rem)]" : ""
                } transition-all mt-[5.5rem] lg:mt-[6.75rem]`}
              >
                {children}
                <MaterialFooter />
              </Stack>
            </Stack>

            <BottomAppBar />
          </main>
        </Paper>
      </div>
    </MaterialThemeProvider>
  );
};

import { Stack, Typography } from "@mui/material";
import React from "react";
import { IMaterialFooterHelpLinks } from "../../../../../interfaces/globalComponents/layout/material/materialFooter/IMaterialFooterHelpLinks";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import Link from "next/link";

export const MaterialFooterHelpLinks = ({
  title,
  links,
}: IMaterialFooterHelpLinks) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  return (
    <Stack className="lg:space-y-[40px]">
      <Typography
        sx={{
          color: componentColors.surface.onSurfaceDetachable,
        }}
        className="text-[20px] font-medium"
      >
        {title}
      </Typography>

      <Stack className="space-y-[18px]">
        {links.map((item, i) => {
          return (
            <Link key={i} href={item.link}>
              <Typography
                className="text-[16px]"
                sx={{
                  color: componentColors.primary.primary,
                }}
              >
                {item.title}
              </Typography>
            </Link>
          );
        })}
      </Stack>
    </Stack>
  );
};

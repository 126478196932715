import React from "react";
import { cn } from "../../../helpers/cn";

type StoreImageProps = React.ComponentProps<"img">;

export const StoreImage = ({ className, src, alt }: StoreImageProps) => {
  return (
    <img
      src={src}
      alt={alt}
      className={cn(`w-36 md_2:w-auto object-cover cursor-pointer `, className)}
    />
  );
};
